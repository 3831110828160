import { memo, useEffect } from 'react';

type Props = {
  children: string;
  tabIndex?: string | number,
};

const PageTitle = (props: Props) => {
  useEffect(() => {
    if (props.children !== undefined) {
      document.title = `${props.children} | Cheng & Tsui Web App`;
    }
  }, [props.children]);
  return null;
};

export default memo(PageTitle);
