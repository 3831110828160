import { USER_CHECK_TOKEN } from '../actionTypes';

type CheckTokenSuccessPayload = {
  token: string;
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  institution: string;
  occupation: string;
  country: string;
  state: string;
  emailUpdatesEnabled: boolean;
  agree_privacy_policy: boolean;
  highlights: any[];
  isBetaTester: boolean;
};

export const checkUserTokenSuccess = (payload: CheckTokenSuccessPayload) =>
  ({
    type: USER_CHECK_TOKEN,
    payload,
    error: false,
  } as const);
