import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import StandaloneUILayout, { sizes } from '../StandaloneUILayout';
import StandaloneUIBox from '../StandaloneUIBox';
import PageTitle from '../PageTitle';
import Button, { colors } from '../Button';

import notFoundImg from './not-found-bg.jpg';

import { propsEqual } from 'utils/memo';
import * as pathnames from 'pathnames';

import styles from './NotFound.module.scss';
const cx = classnames.bind(styles);

type Props = {
  userLoggedIn: boolean;
  history: import('history').History;
};

const NotFound = ({ userLoggedIn, history }: Props) => {
  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goToDashboard = () => {
    history.push(pathnames.DASHBOARD, { disableLoading: true });
  }

  return (
    <StandaloneUILayout size={sizes.large}>
      <PageTitle>Page not found</PageTitle>
      <StandaloneUIBox
      className={cx('not-found-box')}
      style={{
        background:`
          linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
          url(${notFoundImg})
        `
      }}
      >
        <h1 className={cx('heading')}>Looks like no one's here...</h1>
        <h2 className={cx('heading-error')}>404 page not found</h2>
        <div className={cx('message')}>
          The page you're looking for has been removed, renamed,
          <br /> or is temporarily unavailable.
        </div>
        <div className={cx('actions')}>
          <Button
            className={cx('action')}
            color={colors.accent}
            onClick={handleGoBack}
          >
            <svg
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 490 490"
              width="14px"
              height="14px"
            >
              <g><g>
              <g>
                <path
                  d="M474.459,0v490L15.541,244.991L474.459,0z"
                  data-original="#000000"
                  className="active-path"
                  data-old_color="#000000"
                  fill="#FFFFFF"
                />
              </g>
              <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
              <g></g><g></g><g></g><g></g><g></g><g></g><g></g></g></g>
            </svg>
            Go Back
          </Button>
          {userLoggedIn ? (
            <Button
              className={cx('action')}
              onClick={goToDashboard}
            >
              Return to Dashboard
            </Button>
          ) : (
            <Button
              className={cx('action')}
              as={Link}
              to={pathnames.AUTH_LOGIN}
            >
              Return to Login
            </Button>
          )}
        </div>
      </StandaloneUIBox>
    </StandaloneUILayout>
  );
};

export default memo(NotFound, propsEqual(['history', 'userLoggedIn']));
