import React, { memo, ReactNode, ReactNodeArray } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import NotFound from '../NotFound';

import { getUserHasToken } from 'modules/selectors';

type Props = RouteComponentProps & {
  children: ReactNode | ReactNodeArray;
  location: any;
};

const CaptureRouteNotFound = ({ children, location, history }: Props) => {
  const userHasToken = useSelector(getUserHasToken);
  if (location.state && location.state.notFound) {
    return <NotFound history={history} userLoggedIn={userHasToken} />;
  }

  return <>{children}</>;
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.children === nextProps.children &&
  prevProps.location.state === nextProps.location.state &&
  prevProps.history === nextProps.history;
export default withRouter(memo(CaptureRouteNotFound, areEqual));
