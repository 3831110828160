type CachedStorage<T> = {
  version: number;
  data: T;
};

function createGetterForCachedStorage<T extends CachedStorage<any>>(
  key: string,
  initialState: T
) {
  return (): T['data'] => {
    const cached = window.localStorage.getItem(key);
    if (cached) {
      const parsed: T = JSON.parse(cached);
      if (parsed.version < initialState.version) {
        return initialState.data;
      }
      return parsed.data;
    }
    return initialState.data;
  };
}

function createUpdaterForCachedStorage<T extends CachedStorage<any>>(
  key: string,
  initialState: T
) {
  return (update: T['data']) => {
    const cached = window.localStorage.getItem(key);
    const parsed: CachedStorage<T> = cached ? JSON.parse(cached) : initialState;
    window.localStorage.setItem(
      key,
      JSON.stringify({
        version: initialState.version,
        data: {
          ...parsed.data,
          ...update,
        },
      })
    );
  };
}

const cachedWorkbooksKey = 'cachedWorkbooks';
const cachedWorkbooksInitial = {
  version: +process.env.REACT_APP_CACHED_WORKBOOKS_VERSION!,
  data: {},
};
type CachedWorkbooksStorage = CachedStorage<
  import('models/workbooks').WorkbooksById
>;
export const getCachedWorkbooks = createGetterForCachedStorage<
  CachedWorkbooksStorage
>(cachedWorkbooksKey, cachedWorkbooksInitial);
export const updateCachedWorkbooks = createUpdaterForCachedStorage<
  CachedWorkbooksStorage
>(cachedWorkbooksKey, cachedWorkbooksInitial);

const cachedImagesKey = 'cachedImages';
const cachedImagesInitial = {
  version: +process.env.REACT_APP_CACHED_IMAGES_VERSION!,
  data: {},
};
type CachedImagesStorage = CachedStorage<
  import('models/workbookImages').WorkbookImagesById
>;
export const getCachedImages = createGetterForCachedStorage<
  CachedImagesStorage
>(cachedImagesKey, cachedImagesInitial);
export const updateCachedImages = createUpdaterForCachedStorage<
  CachedImagesStorage
>(cachedImagesKey, cachedImagesInitial);
