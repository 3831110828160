import { fetchGet } from 'lib/fetchFns';
import { getResponseValue } from 'lib/drupalFns';

type LoadWorkbookImagesResponse = import('models/workbookImages').LoadWorkbookImagesResponse;
type WorkbookImagesById = import('models/workbookImages').WorkbookImagesById;
type LoadUserWorkbookImagesPayload = {
  requiredImagesIds: number[];
  cachedImagesIds: number[];
};

export async function loadUserWorkbookImages(
  req: Request,
  payload: LoadUserWorkbookImagesPayload
) {
  const requestWorkbookImagesIds = payload.requiredImagesIds.filter(
    id => !payload.cachedImagesIds.includes(id)
  );
  if (requestWorkbookImagesIds.length > 0) {
    const imagesRequest = new Request(
      `${
        req.url
      }/collection_image.json?___t=${new Date().getTime()}&target_id=${requestWorkbookImagesIds.join(
        ','
      )}`,
      {
        credentials: req.credentials,
      }
    );
    const imagesResponse = await fetchGet<LoadWorkbookImagesResponse>(
      imagesRequest
    );
    return imagesResponse.data.reduce(
      (images, image) => {
        const imageId = getResponseValue(image, 'mid');
        return {
          ...images,
          [imageId]: {
            id: +imageId,
            uuid: getResponseValue(image, 'uuid'),
            url: getResponseValue(image, 'image')[0].url,
          },
        };
      },
      {} as WorkbookImagesById
    );
  }
  return {};
}
