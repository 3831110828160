import React, { memo } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames/bind';

import Button from '../Button';

import * as pathnames from 'pathnames';

import styles from './Footer.module.scss';
const cx = classnames.bind(styles);

type Props = RouteComponentProps;

const Footer = ({ location }: Props) => (
  <footer className={cx('footer')} role="none">
    <div role="none" tabIndex={0}>
      If you need assistance with technical or customer issues on the ChengTsui
      Web App, please submit your request
      <Button
        as={Link}
        to={`${pathnames.CUSTOMER_SUPPORT}?from=${location.pathname}`}
        className={cx('footer-button')}
      >
        Customer Support
      </Button>
    </div>
    {location.pathname === pathnames.AUTH_LOGIN && <div className={cx('footer-policy')}>
      <a
        href={pathnames.TERMS_OF_USE}
        target="_blank"
        rel="noopener noreferrer"
        title="Terms of Use"
      >
        Terms of Use
      </a>
      <span>and</span>
      <a
        href={pathnames.PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
        title="Privacy Policy"
      >
        Privacy Policy
      </a>
    </div>}
  </footer>
);

export default withRouter(memo(Footer));
