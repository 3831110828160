import { fetchPost, route, query } from 'lib/fetchFns';

type LoadLicensesResponse = import('models/licenses').LoadLicensesResponse;
type LicenseById = import('models/licenses').LicensesById;
type LoadUserLicensesPayload = {
  userId: number;
  // closedGroupLicensesUuids: string[];
  closedGroupLicensesUuids: any;
};

export async function loadUserLicenses(
  req: Request,
  payload: LoadUserLicensesPayload
) {
  const request = query(route(req, '/user_licenses'), {
    _format: 'json',
    ___t: new Date().getTime().toString(),
  });
  const response = await fetchPost<LoadLicensesResponse>(request, {
    user_id: payload.userId,
    uuids:
      payload.closedGroupLicensesUuids.length > 0
        ? payload.closedGroupLicensesUuids
        : [''],
  });

  // Normalize
  return response.data.reduce(
    (result, license) => {
      result[license.id] = {
        id: +license.id,
        uuid: license.uuid,
        expiresAutomatically: !!+license.expires_automatically,
        expiryDate: +license.expiry,
        createdDate: +license.created,
        langcode: license.langcode,
        defaultLangcode: !!+license.default_langcode,
        edition: license.field_edition_value,
        term: license.field_term_value,
        seats: +license.field_seats_value,
        extensionCount: +license.field_extension_count_value,
        isActivated: !!+license.field_is_activated_value,
        isTrial: !!+license.field_is_trial_value,
        isTextbook: !+(license.field_no_textbook_value || false),
        isStandaloneTextbook: !!+(
          license.field_standalone_textbook_value || false
        ),
        isOwnedByCurrentUser: +license.user_id === payload.userId,
        workbookId: +license.licensed_entity,
        field_license_educator: license.field_license_educator_target_id,
        isAdministrator:
          license.field_license_educator_target_id === license.user_id ||
          license.user_id === payload.userId.toString(),
      };
      return result;
    },
    {} as LicenseById
  );
}
