import { fetchGet } from 'lib/fetchFns';
import { getResponseValue } from 'lib/drupalFns';

type LoadWorkbooksResponse = import('models/workbooks').LoadWorkbooksResponse;
type WorkbooksById = import('models/workbooks').WorkbooksById;
type LoadUserWorkbooksPayload = {
  requiredWorkbooksIds: number[];
  cachedWorkbooksIds: number[];
};

export async function loadUserWorkbooks(
  req: Request,
  payload: LoadUserWorkbooksPayload
) {
  const requestWorkbooksIds = payload.requiredWorkbooksIds.filter(
    id => !payload.cachedWorkbooksIds.includes(id)
  );
  if (requestWorkbooksIds.length > 0) {
    const workbooksRequest = new Request(
      `${
        req.url
      }/collections.json?___t=${new Date().getTime()}&licensed_entity=${requestWorkbooksIds.join(
        ','
      )}`,
      {
        credentials: req.credentials,
      }
    );
    const workbooksResponse = await fetchGet<LoadWorkbooksResponse>(
      workbooksRequest
    );
    return workbooksResponse.data.reduce(
      (workbooks, workbook) => {
        const workbookId = getResponseValue(workbook, 'id');
        const workbookImage = getResponseValue(
          workbook,
          'field_image_workbook'
        );
        return {
          ...workbooks,
          [workbookId]: {
            id: +workbookId,
            uuid: getResponseValue(workbook, 'uuid'),
            label: getResponseValue(workbook, 'label'),
            imageId: getResponseValue(workbook, 'field_image')[0].target_id,
            workbookImageId:
              workbookImage.length > 0 ? workbookImage[0].target_id : undefined,
          },
        };
      },
      {} as WorkbooksById
    );
  }
  return {};
}
