import produce from 'immer';

import { getSession } from 'services/storage';
import { initialState } from 'modules/reducers/userReducer';

import configureStore from './configure';

function rehydrateUser() {
  try {
    return {
      user: produce(initialState, draft => {
        draft.token.value = getSession().token;
      }),
    };
  } catch {
    return {};
  }
}

export default configureStore();
