import React from 'react';
import classnames from 'classnames/bind';

import styles from './TermsOfUse.module.scss';
const cx = classnames.bind(styles);

const TermsOfUse = () => (
  <div className={cx('TermsOfUse')} role="none" tabIndex={0}>
    <h1>ChengTsui Web App™ Terms of Use</h1>
    <span>
      PLEASE READ THIS AGREEMENT CAREFULLY. IT CONTAINS IMPORTANT<br />
      INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS. 
    </span>
    <p>
      Welcome to Cheng &amp; Tsui (“Cheng &amp; Tsui”, “we”, “us”). By indicating your acceptance of the
      following terms of use agreement (“Terms”), you (or your parent or legal guardian on your
      behalf if you are a minor) confirm that you have read, understood, and agree to be bound by
      and comply with the Terms in their entirety. Accordingly, you should review the Terms
      thoroughly before using or registering with the ChengTsui Web App™ (the “CT App”).
    </p>
    <p>
      If you, or your parent or legal guardian, do not agree with any part of the Terms, then you may
      not use or access the CT App. Cheng &amp; Tsui does not knowingly offer services through the CT
      App to anyone under the age of 13 years. If you are under 13 years of age, you may use the CT
      App only with the consent or involvement of a parent or guardian. 
    </p>
    <p>
      We may revise and update these Terms from time to time. If you are a registered user, we may
      attempt to inform you of any material changes by notification to the email address you have
      provided as part of your registration information. Otherwise, you may view the most recent
      Terms within the CT App and on the Cheng &amp; Tsui website. Your continued use of the CT App
      after a revised version of the Terms is posted will be considered your acceptance of that
      revision.
    </p>
    <h2>License and Conditions of Use</h2>
    <p>
      Conditioned on your compliance with these Terms, we grant to you a personal, revocable,
      limited, non-exclusive, non-transferable license to use the CT App solely for your personal,
      non-commercial, and educational purposes. We reserve all rights of ownership in and to the CT
      App not expressly granted to you.
    </p>
    <p>
      The CT App and its content, including but not limited to any and all text, graphics, images,
      photographs, video, audio, and computer code (collectively the “App Content”), and all
      copyrights therein, are owned by Cheng &amp; Tsui, and may be protected by copyright, trademark,
      patent, trade secret, and/or other laws. Cheng &amp; Tsui owns and retain all rights, including the
      worldwide copyright, in the App Content solely and exclusively, for the duration of the rights
      in each country, in all languages, and throughout the universe. Without limiting the foregoing,
      certain App Content may be used by Cheng &amp; Tsui pursuant to licenses from third-party
      suppliers and contributors. The copyright in such third-party content remains with the owners
      thereof. All rights not expressly granted herein are fully reserved.
    </p>
    <p>
      You may not use the App Content in any way whatsoever except as in compliance with these
      Terms. You may not modify, rent, lease, loan, sell, distribute, redistribute, or create derivatives
      works based on the App Content. You may not alter or delete any proprietary notices from App
      Content.
    </p>
    <h2>Purchases</h2>
    <p>
      We may make products available for purchase through the CT App. To purchase any products
      through the CT App, you must be at least the applicable age of majority in your jurisdiction of
      residence.
    </p>
    <p>
      <b>Methods of Payment, Credit Card Terms and Taxes.</b> All payments must be made by one of
      the payment methods specified within the CT App. You hereby represent and warrant that you
      will not use any credit card or other form of payment unless you have all necessary legal
      authorization to do so. You agree to pay all fees and charges incurred in connection with your
      purchases (including any applicable taxes).
    </p>
    <p>
      <b>Order Acceptance.</b> Subject to applicable law, we reserve the right at any time after receipt of
      your order to accept or decline your order for any reason. We may require additional
      verifications or information before accepting any order. Notwithstanding the foregoing, you
      agree that, if we cancel all or a portion of your order or if we provide you less than the quantity
      you ordered, your sole and exclusive remedy is either that (i) we will issue a credit in the
      amount charged for the cancelled portion or the quantity not provided (if you have already been
      charged for the order), or (ii) we will not charge you for the cancelled portion of the order or
      the quantity not provided. 
    </p>
    <p>
      <b>Pricing and Availability.</b> The purchase of products through the CT App is subject to
      availability. PRODUCTS AND SERVICES DISPLAYED ON THE CT APP MAY NOT BE
      AVAILABLE AT ALL TIMES AND MAY BE SUBSTITUTED OR DISCONTINUED AT
      ANY TIME. WE RESERVE THE RIGHT, AT ANY TIME, TO CHANGE PRICES AND
      BILLING METHODS FOR PRODUCTS EFFECTIVE IMMEDIATELY UPON POSTING
      ON THE SERVICE OR BY EMAIL NOTICE TO YOU.
    </p>
    <h2>User-Submitted Content</h2>
    <h3>Restrictions</h3>
    <p>
      The CT App may allow users to upload and/or post content. You shall not upload or post on the
      CT App any content (including without limitation, text, images, video, graphics, music and
      sound) that you do not have the right to upload or post, or content that (i) you know to be false,
      misleading or inaccurate, (ii) is libelous, defamatory, obscene, threatening, invasive of privacy,
      harmful to minors in any way, abusive, illegal or harassing, (iii) contains expressions of hatred,
      bigotry, racism, ethnic prejudice, vulgarity, profanity or is sexually explicit, lewd, obscene or
      pornographic, or is otherwise objectionable, or (iv) that would constitute or encourage a
      criminal offense, violate the rights of any party or violate any law or encourage or advocate any
      violent act or behavior or constitute a threat to personal or public safety. Uploading or posting
      any such content may result in the immediate termination of your access to the CT App and, if
      appropriate, notification to law enforcement officials. You, and not Cheng &amp; Tsui, are solely
      and entirely responsible for the content you upload or post to the CT App.
    </p>
    <p>
      You shall not upload to, distribute through or otherwise publish through the CT App any
      content that contains viruses or any other computer code, corrupt files or programs designed to
      interrupt, destroy or limit the functionality of the CT App or disrupt any software, hardware, 
      telecommunications, networks, servers or other equipment. Uploading, distributing or
      publishing such content may result in immediate termination of your access to the CT App.
    </p>
    <p>
      We have no obligation to monitor or screen content posted or uploaded by you to the CT App.
      We do, however, reserve the right to review such content and remove, edit, or refuse to post it
      as we in our sole discretion see fit. We have no liability or responsibility for the storage,
      modification or deletion of any information or content that you upload or post to the CT App.
    </p>
    <h3>Ownership</h3>
    <p>
      Except as expressly provided in these Terms of Use or as expressly authorized in writing by
      you, you retain all rights, title and interest in and to content uploaded or posted to the CT App
      (“Your Content”). By submitting content to the CT App, you (i) acknowledge and agree that
      Your Content is non-confidential, and (ii) grant to Cheng & Tsui a royalty-free, perpetual,
      irrevocable, non-exclusive, worldwide right and license to use, copy, reproduce, modify, adapt,
      publish, translate, perform, display, make derivative works of and distribute Your Content (in
      whole or in part) on the CT App or other third parties as authorized by your educational
      institution. You authorize us to publicly share Your Content and/or to identify you with Your
      Content.
    </p>
    <p>
      You shall not upload, post or otherwise make available on the CT App any content protected by
      copyright, trademark or other proprietary right without the express permission of the owner of
      the copyright, trademark or other proprietary right. You shall be solely liable for any damage
      resulting from any claims of infringement of copyrights or other proprietary rights, any claims
      by third parties regarding Cheng &amp; Tsui’s exercise of the foregoing license, and any other harm
      resulting from your submission of content or our use or posting of such content.
    </p>
    <h2>Disclaimers</h2>
    <p>
      You expressly agree that use of the CT App is at your sole risk. Cheng &amp; Tsui shall not be liable
      for any loss, liability, cost or damage arising either directly or indirectly from your access to or
      use of (or inability to access or use) the CT App, including, but not limited to, any loss of use,
      system damage, impairment or interruption, lost data, personal injury or any other pecuniary or
      financial loss, whether in an action of contract, negligence, equity or other legal theory. It is
      solely your responsibility to evaluate the accuracy, completeness and usefulness of all
      information, services and other content provided on the CT App.
    </p>
    <h2>No Warranties</h2>
    <p>
      TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, CHENG &amp; TSUI
      PROVIDES THE CT APP “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF
      ANY KIND, EITHER EXPRESS OR IMPLIED. CHENG &amp; TSUI MAKES ABSOLUTELY
      NO WARRANTIES WHATSOEVER, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
      POSSIBLE UNDER APPLICABLE LAWS, CHENG &amp; TSUI DISCLAIMS ALL
      WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
      PURPOSE, NON-INFRINGEMENT, OR OTHER VIOLATIONS OF RIGHTS.
    </p>
    <p>
      CHENG &amp; TSUI DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
      REGARDING THE USE, VALIDITY, ACCURACY, OR RELIABILITY OF, OR THE 
      RESULTS OF THE USE OF, OR OTHERWISE RESPECTING, THE CT APP. YOUR USE
      OF THE CT APP IS SOLELY AT YOUR OWN RISK.
    </p>
    <p>
      Cheng &amp; Tsui does not warrant that the CT App will be uninterrupted or error free; nor does
      Cheng &amp; Tsui make any warranty as to the results that may be obtained from use of the CT App,
      or as to the accuracy or reliability of any information, service, or materials provided through the
      CT App.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      NEITHER CHENG &amp; TSUI NOR ANY OTHER INDEMNIFIED PARTY WILL BE LIABLE
      TO YOU FOR (I) LOST INCOME, LOST PROFITS, LOSS OF DATA, ANY DIRECT,
      INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, OR
      CLAIMS OF THIRD PARTIES, (II) ANY LOSS OR DAMAGE OF ANY KIND INCURRED
      AS A RESULT OF THE USE OF OR RELIANCE UPON THE COMPLETENESS OR
      ACCURACY OF ANY INFORMATION MADE AVAILABLE VIA THE CT APP. THESE
      LIMITATIONS OF LIABILITY FOR SUCH LOSSES WILL APPLY EVEN IF WE HAVE
      BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THEIR POSSIBILITY.
    </p>
    <h2>Indemnification</h2>
    <p>
      You agree to indemnify, defend (at Cheng &amp; Tsui’s option) and hold Cheng &amp; Tsui and its
      affiliates, officers, directors, representatives, agents, partners, employees and third party content
      suppliers and contributors (collectively, “Indemnified Parties”) harmless from and against any
      and all claims, liabilities, losses, and expenses (including damage awards, settlement amounts,
      and reasonable legal fees), brought against any Indemnified Person, arising out of or related to
      Your Content, your use of the CT App other than as expressly permitted under these Terms of
      Use, your violation of these Terms or any third party's rights. Any such indemnification shall be
      conditioned on the Indemnified Party (a) notifying you in writing of any such claim, liability,
      loss, expense or threat thereof, (b) cooperating with you in the defense or settlement thereof, and
      (c) allowing you to control such defense or settlement. The Indemnified Party shall be entitled to
      participate in such defense at its own cost and expense.
    </p>
    <h2>Arbitration</h2>
    <p>
      THE FOLLOWING TERMS TO WHICH YOU ARE CONSENTING CONSIST OF A
      BINDING ARBITRATION PROVISION AND A CLASS-ACTION AND JURY TRIAL
      WAIVER.
    </p>
    <p>
      To the fullest extent permitted by law, you agree that if a dispute arises between you and one or
      more of the Indemnified Parties relating in any way to the CT App or your use thereof, including
      common law or statutory claims, BOTH YOU AND THE INDEMNIFIED PARTIES SHALL
      SUBMIT TO BINDING ARBITRATION BEFORE THE AMERICAN ARBITRATION
      ASSOCIATION. ANY ARBITRATION COMMENCED BETWEEN YOU AND THE
      INDEMNIFIED PARTIES MUST BE ARBITRATED IN BOSTON, MASSACHUSETTS.
      ARBITRATION MUST BE ON AN INDIVIDUAL BASIS. THIS MEANS NEITHER YOU
      NOR THE INDEMNIFIED PARTIES MAY JOIN CLAIMS IN ARBITRATION WITH OR
      AGAINST OTHER USERS, OR LITIGATE IN COURT OR ARBITRATE ANY CLAIMS AS
      A REPRESENTATIVE OR MEMBER OF A CLASS. YOU UNDERSTAND THAT BY 
      AGREEING TO ARBITRATION YOU ARE WAIVING CERTAIN LEGAL RIGHTS,
      INCLUDING THE RIGHT TO SUE IN COURT, THE RIGHT TO HAVE THE DISPUTE
      DECIDED BY A JUDGE OR JURY, AND THE RIGHT TO BRING, OR BE PART OF, A
      CLASS ACTION CASE. The foregoing shall not apply to suits to enjoin infringement or other
      misuse of intellectual property rights, and you or the Indemnified Parties may bring suit in court
      to enjoin infringement or other misuse of intellectual property rights. Any such suit shall be
      adjudicated by a state or federal court located in Boston, Massachusetts, and you hereby consent
      to jurisdiction in such courts for such suits.
    </p>
    <h2>General Conditions</h2>
    <p>
      These Terms constitute the entire agreement between you and us, and governs your use of the
      CT App superseding any prior agreements (including, but not limited to, any prior versions of
      these Terms). These Terms shall be binding upon and inure to the benefit of the parties hereto
      and their respective heirs, executors, administrators, legal representatives, successors and
      permitted assigns.
    </p>
    <p>
      No waiver of any provision of these Terms or any breach hereunder shall be deemed a waiver of
      any other provision or subsequent breach, nor shall any such waiver constitute a continuing
      waiver. If any part of these Terms, or the application thereof to any person or circumstance, is for
      any reason held invalid or unenforceable, it shall be deemed severable and the validity of the
      remainder of these Terms or the applications of such provision to other persons or circumstances
      shall not be affected thereby.
    </p>
    <h2>Contact Us</h2>
    <p>
      If you have any questions or concerns about these Terms or wish to contact us for any other
      reason, please <a href="https://www.cheng-tsui.com/contact" target="_blank" rel="noopener noreferrer">click here</a>.
    </p>
  </div>
);

export default TermsOfUse;
