import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { startLoading, finishLoading } from 'modules/allActions';

import { alwaysEqual } from 'utils/memo';

const Loading = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoading());
    return function cleanup() {
      dispatch(finishLoading());
    };
  }, [dispatch]);
  return null;
};

export default memo(Loading, alwaysEqual);
