import React, { PureComponent } from 'react';
import cx from 'classnames';

// Loader styles will load instantly from styles/main.scss, because we need to
// show it while polyfills loading

const preventFlashingDuration = 200;

type Props = {
  active: boolean;
};

type State = {
  visible: boolean;
  showFadeOut: boolean;
};

class Loader extends PureComponent<Props, State> {
  state = {
    visible: this.props.active,
    showFadeOut: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.active && !prevProps.active) {
      window.setTimeout(() => {
        if (this.props.active) {
          this.setState({ showFadeOut: false, visible: true });
        }
      }, preventFlashingDuration);
    } else if (!this.props.active && prevProps.active && this.state.visible) {
      window.setTimeout(() => {
        if (!this.props.active && this.state.visible) {
          this.setState({ showFadeOut: true, visible: true });
          window.setTimeout(() => {
            this.setState({ showFadeOut: false, visible: false });
          }, preventFlashingDuration);
        }
      }, preventFlashingDuration);
    }
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    return (
      <div
        className={cx(
          'loaderWrapper',
          this.state.showFadeOut
            ? 'loaderWrapper--fadeOut'
            : 'loaderWrapper--fadeIn'
        )}
      >
        <div className={cx('loader')}>
          <div className={cx('loader-ball')} />
          <div className={cx('loader-circle')} />
        </div>
      </div>
    );
  }
}

export default Loader;
