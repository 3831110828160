import React, { createElement, memo, ReactNode, ReactNodeArray, ElementType } from 'react';
import classNames from 'classnames/bind';

import styles from './Button.module.scss';
const cx = classNames.bind(styles);

export const colors = Object.freeze({
  accent: 1,
  primary: 2,
  secondary: 3,
  transparentDark: 4,
  transparentLight: 5,
} as const);
type Color = (typeof colors)[keyof (typeof colors)];

type Props<T> = {
  as: ElementType<T>;
  children: ReactNode | ReactNodeArray;
  fullWidth?: boolean;
  color?: Color;
  className?: string;
} & T;

const Button = <T extends any>({
  as = 'button',
  className = '',
  fullWidth = false,
  color = colors.secondary,
  ...props
}: Props<T>) => {
  return createElement(as, {
    className: cx(
      'button',
      {
        '--full-width': fullWidth,
        '--color-accent': color === colors.accent,
        '--color-primary': color === colors.primary,
        '--color-secondary': color === colors.secondary,
        '--color-transparent-dark': color === colors.transparentDark,
        '--color-transparent-light': color === colors.transparentLight,
      },
      className
    ),
    ...props,
  });
};

export default memo(Button);
