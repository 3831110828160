import cloneRequestWithUrl from './cloneRequestWithUrl';

function query(request: Request, queryObject: import('./types').QueryObject) {
  const url = new URL(request.url);
  Object.keys(queryObject).forEach(key => {
    if (Array.isArray(queryObject[key])) {
      (queryObject[key] as string[]).forEach(value =>
        url.searchParams.append(key, value)
      );
    } else {
      url.searchParams.append(key, queryObject[key] as string);
    }
  });

  return cloneRequestWithUrl(url.toString(), request);
}

export default query;
