import { createSelector } from 'reselect';

import { getUserToken } from './getUserToken';

import { setHeader } from 'lib/fetchFns';
import { d8Request } from 'utils/requests';

export const getUserRequest = createSelector(
  getUserToken,
  token => {
    if (token.value) {
      return setHeader(d8Request, 'X-CSRF-Token', token.value);
    }
    return d8Request;
  }
);
