import React, { memo, AllHTMLAttributes } from 'react';
import classnames from 'classnames/bind';

import styles from './StandaloneUIBox.module.scss';
const cx = classnames.bind(styles);

type Props = AllHTMLAttributes<HTMLDivElement>;

const StandaloneUIBox = ({ className, ...props }: Props) => (
  <section className={cx('box', className)} {...props} />
);

export default memo(StandaloneUIBox);
