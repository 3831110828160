import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUserHasToken } from 'modules/selectors';

import { alwaysEqual } from 'utils/memo';

const RootRedirect = () => (
  <Redirect to={useSelector(getUserHasToken) ? '/dashboard' : '/auth/login'} />
);

export default memo(RootRedirect, alwaysEqual);
