import React, { memo, createElement, ElementType } from 'react';
import classnames from 'classnames/bind';

import logo from './logo.svg';

import styles from './Logo.module.scss';
const cx = classnames.bind(styles);

type Props<T> = {
  as: ElementType<T>;
  className?: string;
  imgClassName?: string;
} & T;

const Logo = <T extends any>({
  as = 'div',
  className,
  imgClassName,
  ...props
}: Props<T>) => {
  return createElement(as, {
    className: cx('logo', className),
    children: <img src={logo} className={imgClassName} alt="Cheng & Tsui" />,
    ...props,
  });
};

export default memo(Logo);
