import React from 'react';
import classnames from 'classnames/bind';

import styles from './PrivacyPolicy.module.scss';
const cx = classnames.bind(styles);

const PrivacyPolicy = () => (
  <div className={cx('privacyPolicy')} role="none" tabIndex={0}>
    <h1>ChengTsui Web App™ Privacy Policy</h1>
    <p>
      This Privacy Policy is intended to inform you about the types of information gathered by
      Cheng &amp; Tsui (“we” or “us”) when you use the ChengTsui Web App™ (the “CT App”),
      how we use and safeguard that information, and the degree to which you may control the
      maintenance and sharing of your information.
      
    </p>
    <p>
      If you, or your parent or legal guardian, do not agree with any part of this Privacy Policy,
      then you may not use or access the CT App. Cheng &amp; Tsui does not knowingly offer
      services through the CT App to anyone under the age of 13 years. If you are under 13
      years of age, you may use the CT App only with the consent or involvement of a parent
      or guardian.
    </p>
    <p>
      We may update this Privacy Policy from time to time. If you are a registered CT App
      user, we may attempt to inform you of any material changes by notification to the email
      address you have provided as part of your registration information. Otherwise, you may
      view the most recent Privacy Policy within the CT App. Your continued use of the CT
      App after a revised version of this Privacy Policy is posted will be considered your
      acceptance of that revision.
    </p>
    <h2>Information Collection - General</h2>
    <p>
      If you choose to register with the CT App, we will ask you to provide certain personally
      identifiable information such as your name, email address, occupation, country and state
      of residence, and educational institution.
    </p>
    <p>
      The CT App may allow you to upload and/or post your own content, such as information
      about yourself for your profile, text content, and audio and video recordings. By
      uploading or posting this content, you are knowingly choosing to provide and disclose it
      to us and to allow us to collect and store it.
    </p>
    <h3>Analytics Information</h3>
    <p>
      We may automatically collect and store certain non-personally identifiable information
      about your use of the CT App. This information may include internet protocol (IP)
      addresses, browser type, mobile device type, date/time stamps, and related data. We use
      this information, which does not identify individual users, solely to improve the quality
      of our products and services. Out of respect for your privacy, we do not link this
      automatically-collected data to personally identifiable information. 
    </p>
    <h3>Cookies</h3>
    <p>
      A cookie is a small text file that is stored on a user’s computer. We use session cookies to
      make it easier for you to use the CT App. A session ID cookie expires when you close
      your browser. We use tracking cookies to better understand how you use the CT App,
      and to enhance your user experience. A tracking cookie remains on your hard drive for an
      extended period of time. You are free to decline cookies, but by doing so, you may not be
      able to take full advantage of all feature of the CT App. We do not link the information
      we store in cookies to any personally identifiable information you submit while using the
      CT App.
    </p>
    <h3>California Do Not Track Notice</h3>
    <p>
      We do not track our users over time and across third party websites, and therefore the CT
      App does not respond to Do Not Track signals sent by your browser or mobile
      application. We do not authorize the collection by third parties of personally identifiable
      information from CT App users.
    </p>
    <h2>Information Collection – Students</h2>
    <p>
      We collect information about K-12 students using the CT App. Our collection and use of
      that information complies with the requirements of the Children’s Online Privacy
      Protection Act (“COPPA”), Protection of Pupil Rights Amendment (“PPRA”), and the
      Family Educational Rights and Privacy Act (“FERPA”).
    </p>
    <p>
      COPPA permits an educational institution to provide required consents regarding the
      collection and use of personal information of students who are under the age of 13.
      Where an educational institution is the subscriber to the CT App, we collect and use
      student information pursuant to this form of COPPA consent. We share this Privacy
      Policy with the institution to ensure that it may be assured that our practices comply with
      COPPA.
    </p>
    <p>
      FERPA permits an educational institution to provide educational records to service
      providers without requiring the institution to obtain specific parental consent. In this
      event, the service provider acts as a type of “school official” by performing services that
      would otherwise be performed by employees of the educational institution. We fulfill
      FERPA requirements for qualifying as a “school official” by giving the institution direct
      control with respect to the use and maintenance of the education records at issue and not
      disclosing or using the information contained in those records other than to provide the
      CT App to the institution. We comply with FERPA by relying on this form of consent.
    </p>
    <p>
      PPRA requires that schools obtain written consent from parents before minor students are
      required to participate in any U.S. Department of Education funded survey, analysis, or
      evaluation that reveals information concerning the following areas:
    </p>
    <ol>
      <li>Political affiliations;</li>
      <li>Mental and psychological problems potentially embarrassing to the student andhis/her family;</li>
      <li>Sex behavior and attitudes;</li>
      <li>Illegal, anti-social, self-incriminating and demeaning behavior;</li>
      <li>Critical appraisals of other individuals with whom respondents have close family relationships;</li>
      <li>
        Legally recognized privileged or analogous relationships, such as those of lawyers, physicians, and ministers;
      </li>
      <li>Religious practices, affiliations, or beliefs of the student or student's parent; or</li>
      <li>
        Income (other than that required by law to determine eligibility for participation
        in a program or for receiving financial assistance under such program.)
      </li>
    </ol>
    <p>
      We comply with PPRA by requiring institutions to secure and provide us with copies of
      all such written consents.
    </p>
    <h2>Information Use and Disclosure</h2>
    <p>We use the personal information we have collected for the following purposes:</p>
    <ul>
      <li>
        To provide you with access to the CT App and for related customer service and account management purposes.
      </li>
      <li>
        To provide you with information about new features, products, sales and events
        (see “Notifications and Opt-Out” below).
      </li>
      <li>
        To understand how you use the CT App and its content, including associating you
        with the different devices you may use to access the CT App, for analytics and
        product development purposes, as well as personalize offers that we make to you.
      </li>
      <li>
        To manage our everyday business needs, such as account management, contract
        management, business continuity and disaster recovery, security and fraud
        prevention, corporate governance, reporting and legal compliance.
      </li>
    </ul>
    <h3>Notifications and Opt-Out</h3>
    <p>
      When you register to use the CT App, we will send you a welcome email including
      information about the CT App and your account. We also give you the opportunity to
      sign up to receive emails from us about new products, sales, and events. Out of respect
      for your privacy, if you no longer wish to receive these notifications, you may opt-out of
      receiving them as instructed in each email. 
    </p>
    <p>
      Be advised, however, that you may not opt-out of receiving email notifications from us
      related to technical support or customer services requests, or changes to the CT App
      Terms of Use or Privacy Policy.
    </p>
    <h3>Disclosure</h3>
    <p>
      We understand how highly our users value the privacy and security of their personally
      identifiable information. We will disclose information collected from and about you to
      the following entities for the following purposes: (1) to our related companies, service
      providers and suppliers, to enable them to fulfill a product or service request or to
      perform a business, professional or technical support function for us; (2) as necessary if
      we believe that there has been a violation of our Terms of Use or of our rights or the
      rights of any third party; and (3) to respond to judicial process and provide information to
      law enforcement agencies or in connection with an investigation on matters related to
      public safety, as permitted by law, or otherwise as required by law.
    </p>
    <p>
      We may also share with third parties aggregated information about use of the CT App.
      This data does not personally identify you or provide specific information about
      individual usage.
    </p>
    <h3>Business Transitions</h3>
    <p>
      In the event Cheng &amp; Tsui goes through a business transition, such as a merger,
      acquisition by another company, or sale of all or a portion of its assets, any personally
      identifiable information we have on record will likely be among the assets transferred.
      The transferred personally identifiable information will remain subject to the provisions
      of this Privacy Policy or any subsequent updated versions.
    </p>
    <h2>Links To Third Party Sites</h2>
    <p>
      The CT App may contain links to third party websites. These sites may have their own
      terms of use and privacy policies which do not apply to Cheng &amp; Tsui or the CT App.
    </p>
    <h2>Information Security</h2>
    <p>
      We employ encryption and other security measures to protect the loss, misuse, and
      alteration of the information under our control. We take commercially reasonable steps to
      protect this information and the transmission of such information. However, please be
      advised that while we make every effort to ensure the integrity and security of our
      network and systems, we cannot guarantee that our security measures will prevent
      unauthorized access from occurring. Please take steps to maintain the security of your
      account information.
    </p>
    <h2>Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy, please
      <a href="https://www.cheng-tsui.com/contact" target="_blank" rel="noopener noreferrer">contact us.</a>
    </p>
  </div>
);

export default PrivacyPolicy;
