// We need to use global styles to show preloader in IE
import 'styles/main.scss';

// Promises are not supported by IE11 so we can't load it dynamically
import 'core-js/stable/promise';

const polyfills: any[] = [];
if (!Object.assign) {
  polyfills.push(import('core-js/stable/object/assign' as any));
}
if (!Object.values) {
  polyfills.push(import('core-js/stable/object/values' as any));
}
if (!Object.entries) {
  polyfills.push(import('core-js/stable/object/entries' as any));
}
if (!Array.prototype.find) {
  polyfills.push(import('core-js/stable/array/find' as any));
}
if (!Array.prototype.includes) {
  polyfills.push(import('core-js/stable/array/includes' as any));
}
if (!Number.isInteger) {
  polyfills.push(import('core-js/stable/number/is-integer' as any));
}
if (!Number.isNaN) {
  polyfills.push(import('core-js/stable/number/is-nan' as any));
}
if (!String.prototype.includes) {
  polyfills.push(import('core-js/stable/string/includes' as any));
}
if (!Function.prototype.name) {
  polyfills.push(import('core-js/stable/function/name' as any));
}
if (!window.URL || !window.URLSearchParams) {
  polyfills.push(import('core-js/stable/url' as any));
}
if (!window.fetch) {
  polyfills.push(import('whatwg-fetch' as any));
}
if (typeof Symbol !== 'function') {
  polyfills.push(import('core-js/stable/symbol' as any));
}
Promise.all(polyfills).then(() => require('./entry'));
