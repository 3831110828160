import { fetchPost, route, query } from 'lib/fetchFns';

type SendAgreePrivacyPolicyPayload = {
  userId: string;
};

export async function sendAgreePrivacyPolicy(req: Request, payload: SendAgreePrivacyPolicyPayload) {
  const request = query(route(req, '/update-user-data'), {});
  return fetchPost(request, {
    data: {
      id: payload.userId,
      attributes: {
        field_agree_privacy_policy: true,
      },
    },
  });
}
