import { fetchGet, query, route } from 'lib/fetchFns';
import { getResponseValue } from 'lib/drupalFns';

export async function loadUserInfo(req: Request) {
  const request = query(route(req, '/user/me'), {
    _format: 'json',
    ___t: new Date().getTime().toString(),
  });
  const { data } = await fetchGet(request);

  const processing = data[0];
  const [
    name = {
      given: '',
      family: '',
    },
  ] = getResponseValue(processing, 'field_name');
  return {
    id: getResponseValue(processing, 'uid'),
    uuid: getResponseValue(processing, 'uuid'),
    firstName: name.given,
    lastName: name.family,
    email: getResponseValue(processing, 'mail').toLocaleLowerCase(),
    username: getResponseValue(processing, 'name'),
    institution: getResponseValue(processing, 'field_organization', ''),
    occupation: getResponseValue(processing, 'field_occupation', ''),
    country: getResponseValue(processing, 'field_country', ''),
    state: getResponseValue(processing, 'field_state', ''),
    emailUpdatesEnabled: getResponseValue(
      processing,
      'field_email_updates',
      false
    ),
    agree_privacy_policy: getResponseValue(
      processing,
      'field_agree_privacy_policy',
      ''
    ),
    highlights: processing.field_highlights,
    isBetaTester: (getResponseValue(processing, 'roles', []) as {
      target_id: string;
    }[]).some(role => role.target_id === 'beta_tester'),
  };
}
