import React, { useEffect, useRef, ReactNode } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FocusTrap from 'focus-trap-react';
import classnames from 'classnames/bind';

import styles from './Dialog.module.scss';
const cx = classnames.bind(styles);

type Props = {
  className?: string;
  width?: string;
  showFadeOut: boolean;
  children: ReactNode;
};

const DialogInner = ({ className, width, showFadeOut, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const target = ref.current;
    if (target) {
      disableBodyScroll(target);
      return () => enableBodyScroll(target);
    }
  }, []);

  return (
    <FocusTrap>
      <div className={cx('spacing')} ref={ref}>
        <div
          className={cx('dialog', { '--fade-out': showFadeOut }, className)}
          style={{ ...(width && { width }) }}
        >
          {children}
        </div>
      </div>
    </FocusTrap>
  );
};

export default DialogInner;
