import React, { memo, lazy, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAfter, addDays } from 'date-fns';

import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';

import RootRedirect from 'components/RootRedirect';
import CaptureRouteNotFound from 'components/CaptureRouteNotFound';
import RouteNotFound from 'components/RouteNotFound';
import Dialog from 'components/Dialog';
import FluencyLinkDialog from 'components/FluencyLinkDialog';

import loggedIn from 'hocs/loggedIn';
import notLoggedIn from 'hocs/notLoggedIn';

import * as pathnames from '../pathnames';
import { pathnameEqual } from 'utils/memo';

import {
  getUserAgreePrivacyPolicy,
  getUserIsLoggedIn,
} from 'modules/selectors';

import { logout } from 'modules/allActions';

import PrivacyPolicyDialog from './PrivacyPolicyDialog';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';

const AuthRoute = notLoggedIn(
  lazy(() =>
    import(
      /* webpackChunkName: 'AuthRoute' */
      /* webpackPrefetch: true */
      './auth/Auth'
    )
  )
);
const DashboardRoute = loggedIn(
  lazy(() =>
    import(
      /* webpackChunkName: 'DashboardRoute' */
      /* webpackPrefetch: true */
      './dashboard/Dashboard'
    )
  )
);
const ReaderRoute = loggedIn(
  lazy(() =>
    import(
      /* webpackChunkName: 'ReaderRoute' */
      /* webpackPrefetch: true */
      './Reader'
    )
  )
);
const CustomerSupportRoute = lazy(() =>
  import(
    /* webpackChunkName: 'CustomerSupportRoute' */
    /* webpackPrefetch: true */
    './CustomerSupport'
  )
);

type Props = RouteComponentProps;

const Root = ({ location }: Props) => {
  const agreePrivacyPolicy = useSelector(getUserAgreePrivacyPolicy);
  const isPrivacyDialogOpen =
    agreePrivacyPolicy &&
    location.pathname !== pathnames.PRIVACY_POLICY &&
    location.pathname !== pathnames.TERMS_OF_USE;

  const dispatch = useDispatch();
  const handleClose = () => dispatch(logout());

  const isLoggedIn = useSelector(getUserIsLoggedIn);
  const [isMigrationDialogOpen, setIsMigrationDialogOpen] = useState(false);
  useEffect(() => {
    if (isLoggedIn) {
      try {
        const lastDate = localStorage.getItem('migrationDialog');
        if (!lastDate || isAfter(new Date(), addDays(new Date(lastDate), 5))) {
          setIsMigrationDialogOpen(true);
        }
      } catch {
        localStorage.removeItem('migrationDialog');
      }
    }
  }, [isLoggedIn]);
  const closeMigrationDialog = () => {
    localStorage.setItem('migrationDialog', new Date().toString());
    setIsMigrationDialogOpen(false);
  };

  if (location.pathname === '/') {
    return <RootRedirect />;
  }

  return (
    <CaptureRouteNotFound>
      <Switch>
        <Route path={pathnames.AUTH} component={AuthRoute} />
        <Route path={pathnames.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route path={pathnames.TERMS_OF_USE} component={TermsOfUse} />
        <Route path={pathnames.DASHBOARD} component={DashboardRoute} />
        <Route
          path={`${pathnames.READER}/:id/:path/:textBook`}
          component={ReaderRoute}
        />
        <Route path={`${pathnames.READER}/:id/:path`} component={ReaderRoute} />
        <Route path={`${pathnames.READER}/:id`} component={ReaderRoute} />
        <Route
          path={pathnames.CUSTOMER_SUPPORT}
          exact
          component={CustomerSupportRoute}
        />
        <RouteNotFound />
      </Switch>
      <Dialog
        open={
          agreePrivacyPolicy &&
          location.pathname !== pathnames.PRIVACY_POLICY &&
          location.pathname !== pathnames.TERMS_OF_USE
        }
        onClose={handleClose}
        width="500px"
      >
        <PrivacyPolicyDialog onDisagree={handleClose} />
      </Dialog>
      {!isPrivacyDialogOpen && isMigrationDialogOpen && (
        <Dialog
          open={isMigrationDialogOpen}
          width="500px"
          onClose={closeMigrationDialog}
        >
          <FluencyLinkDialog onClose={closeMigrationDialog} />
        </Dialog>
      )}
    </CaptureRouteNotFound>
  );
};

export default withRouter(memo(Root, pathnameEqual));
