type RouteComponentProps = import('react-router-dom').RouteComponentProps;

export function alwaysEqual() {
  return true;
}

export function pathnameEqual<T extends RouteComponentProps>(
  prevProps: T,
  nextProps: T
) {
  return prevProps.location.pathname === nextProps.location.pathname;
}

export function searchEqual<T extends RouteComponentProps>(
  prevProps: T,
  nextProps: T
) {
  return prevProps.location.search === nextProps.location.search;
}

export function historyEqual<T extends RouteComponentProps>(
  prevProps: T,
  nextProps: T
) {
  return prevProps.history === nextProps.history;
}

export function childrenEqual<T extends { children: C }, C>(
  prevProps: T,
  nextProps: T
) {
  return prevProps.children === nextProps.children;
}

export function propsEqual<T extends {}>(props: (keyof T)[]) {
  return (prevProps: T, nextProps: T) => {
    return props.every(prop => prevProps[prop] === nextProps[prop]);
  };
}
