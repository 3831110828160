import React, { memo, ReactNode, ReactNodeArray, Suspense } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import Logo from '../Logo';
import Footer from '../Footer';
import Loading from '../Loading';
import MaintenanceMessage from '../MaintenanceMessage';

import styles from './StandaloneUILayout.module.scss';
const cx = classnames.bind(styles);

export const sizes = Object.freeze({
  normal: 1,
  large: 2,
} as const);
type Size = (typeof sizes)[keyof (typeof sizes)];

type Props = {
  className?: string;
  size?: Size;
  footer?: boolean;
  children: ReactNode | ReactNodeArray;
};

const StandaloneUILayout = ({
  className,
  size = sizes.normal,
  footer = true,
  children,
}: Props) => (
  <>
    <MaintenanceMessage />
    <div role="heading" aria-level={1} aria-label="chengtsue"></div>
    <div className={cx('layout')} role="none">
      <Logo
        as={Link}
        className={cx('logo')}
        role="link"
        imgClassName={cx('logo-image')}
        to="/"
      />
      <div
        role="none"
        className={cx(
          'main',
          {
            '--sizeNormal': size === sizes.normal,
            '--sizeLarge': size === sizes.large,
          },
          className
        )}
      >
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </div>
    </div>
    {footer && <Footer />}
  </>
);

export default memo(StandaloneUILayout);
