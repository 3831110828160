import { SUBSCRIPTIONS_LOAD_INFO } from '../actionTypes';

export const loadSubscriptionsInfoSuccess = (payload: {
  groups: import('models/groups').GroupsById;
  licenses: import('models/licenses').LicensesById;
  workbooks: import('models/workbooks').WorkbooksById;
  workbookImages: import('models/workbookImages').WorkbookImagesById;
}) =>
  ({
    type: SUBSCRIPTIONS_LOAD_INFO,
    payload,
    error: false,
  } as const);
