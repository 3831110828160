import produce from 'immer';

import {
  USER_CHECK_TOKEN,
  USER_LOGOUT,
  USER_UPDATE_PROFILE,
  USER_REFRESH_TOKEN,
  LOGIN_SUBMIT,
  USER_SET_HIGHLIGHTS,
} from '../actionTypes';

export const initialState = {
  token: {
    value: '',
    checked: false,
  },
  id: 0,
  uuid: '',
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  institution: '',
  occupation: '',
  country: '',
  state: '',
  emailUpdatesEnabled: false,
  agree_privacy_policy: false,
  highlights: [] as any[],
  isBetaTester: false,
};

const userReducer = (
  state = initialState,
  action: import('store/types').Action
) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN_SUBMIT:
      case USER_CHECK_TOKEN: {
        draft.token.checked = true;
        if (!action.error) {
          draft.token.value = action.payload.token;
          draft.id = action.payload.id;
          draft.uuid = action.payload.uuid;
          draft.firstName = action.payload.firstName;
          draft.lastName = action.payload.lastName;
          draft.email = action.payload.email;
          draft.username = action.payload.username;
          draft.institution = action.payload.institution;
          draft.occupation = action.payload.occupation;
          draft.country = action.payload.country;
          draft.state = action.payload.state;
          draft.emailUpdatesEnabled = action.payload.emailUpdatesEnabled;
          draft.agree_privacy_policy = action.payload.agree_privacy_policy;
          draft.highlights = action.payload.highlights;
          draft.isBetaTester = action.payload.isBetaTester;
        }
        break;
      }
      case USER_LOGOUT: {
        return { ...initialState, token: { value: '', checked: true } };
      }
      case USER_UPDATE_PROFILE: {
        if (!action.error) {
          draft.firstName = action.payload.firstName;
          draft.lastName = action.payload.lastName;
          draft.emailUpdatesEnabled = action.payload.emailUpdatesEnabled;
        }
        break;
      }
      case USER_REFRESH_TOKEN: {
        if (!action.error) {
          draft.token.value = action.payload;
          break;
        }

        return initialState;
      }
      case USER_SET_HIGHLIGHTS: {
        draft.highlights = action.payload;
        break;
      }
    }
  });

export default userReducer;
