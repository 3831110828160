import produce from 'immer';

import { LOADING_START, LOADING_FINISH } from '../actionTypes';

export const initialState = {
  count: 0,
};

const loadingReducer = (
  state = initialState,
  action: import('store/types').Action
) =>
  produce(state, draft => {
    switch (action.type) {
      case LOADING_START: {
        draft.count++;
        break;
      }
      case LOADING_FINISH: {
        draft.count--;
        break;
      }
    }
  });

export default loadingReducer;
