import fetchWithBody from './fetchWithBody';

function fetchPost<R = any, E = any>(
  request: Request,
  data: import('./types').FetchBodyType
) {
  return fetchWithBody<R, E>(new Request(request, { method: 'post' }), data);
}

export default fetchPost;
