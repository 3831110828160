import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
  ReactNode,
  ReactNodeArray,
} from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames/bind';

import DialogInner from './DialogInner';

import styles from './Dialog.module.scss';
const cx = classnames.bind(styles);

type Props = {
  open: boolean;
  onClose: () => void;
  previewIndexOpen?: number | undefined;
  toggleOpenPreview?: (index: number) => () => void;
  width?: string;
  children: ReactNode | ReactNodeArray;
  className?: string;
  wrapperClassName?: string;
};

const fadeDuration = 300;
const root = document.getElementById('dialog');

const Dialog = ({
  open,
  onClose,
  previewIndexOpen,
  toggleOpenPreview,
  children,
  className,
  width,
  wrapperClassName,
}: Props) => {
  const [visible, setVisible] = useState(open);
  const [showFadeOut, setShowFadeOut] = useState(false);

  const el = useRef(document.createElement('div'));
  const modalRef = useRef<any>(null);
  useEffect(() => {
    const element = el.current;
    if (root) {
      root.appendChild(element);
    }
    return function cleanup() {
      if (root) {
        root.removeChild(element);
      }
    };
  }, []);

  const handleCloseByEsc = useCallback(
    event => {
      if (event.which !== 27) return;

      if (toggleOpenPreview !== undefined && previewIndexOpen !== undefined)
        toggleOpenPreview(0);
      else onClose();
    },
    [onClose, toggleOpenPreview, previewIndexOpen]
  );

  useEffect(() => {
    if (!open) return;
    document.addEventListener('keyup', handleCloseByEsc);
    return () => document.removeEventListener('keyup', handleCloseByEsc);
  }, [handleCloseByEsc, open]);

  const timeout = useRef<number | null>(null);
  useEffect(() => {
    if (open) {
      setVisible(true);
    } else {
      setShowFadeOut(true);
      timeout.current = window.setTimeout(() => {
        setVisible(false);
        setShowFadeOut(false);
      }, fadeDuration);
    }
  }, [open]);

  const handleClose = useCallback(
    ({ target, currentTarget }) => {
      if (target === currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`${cx('wrapper', {
        '--fade-out': showFadeOut,
      })} ${wrapperClassName}`}
      onClick={handleClose}
      onKeyUp={() => false}
      tabIndex={0}
      ref={modalRef}
    >
      <DialogInner
        className={className}
        width={width}
        showFadeOut={showFadeOut}
      >
        {children}
      </DialogInner>
    </div>,
    el.current
  );
};

export default memo(Dialog);
