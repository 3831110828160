import fetch from './fetch';

function fetchWithBody<R = any, E = any>(
  request: Request,
  data: import('./types').FetchBodyType
) {
  if (!(data instanceof FormData) && !(data && data.toString() === data)) {
    const headers = new Headers(request.headers);
    headers.append('Content-Type', 'application/json');
    return fetch<R, E>(
      new Request(request, {
        body: JSON.stringify(data),
        headers,
      })
    );
  }

  return fetch<R, E>(
    new Request(request, {
      body: data,
    })
  );
}

export default fetchWithBody;
