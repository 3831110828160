import React, { memo } from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';

import { pathnameEqual } from 'utils/memo';

type Props = RouteComponentProps;

const RouteNotFound = ({ location }: Props) => (
  <Redirect to={{ pathname: location.pathname, state: { notFound: true } }} />
);

export default withRouter(memo(RouteNotFound, pathnameEqual));
