const sessionKey = 'session';

type Session = {
  useLocalStorage: boolean;
  email: string;
  passwrod: string;
  token: string;
};

export function getSession(): Session {
  return JSON.parse(
    (window.sessionStorage.getItem(sessionKey) ||
      window.localStorage.getItem(sessionKey))!
  );
}

export function saveSession(
  useLocalStorage: boolean,
  email: Session['email'],
  password: Session['passwrod'],
  token: Session['token']
) {
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  storage.setItem(
    sessionKey,
    JSON.stringify({
      useLocalStorage,
      email,
      password,
      token,
    })
  );
}

export function modifySession(key: keyof Session, value: string) {
  const inSessionStorage = window.sessionStorage.getItem(sessionKey);
  const inLocalStorage = window.localStorage.getItem(sessionKey);
  if (inSessionStorage || inLocalStorage) {
    const storage = inSessionStorage
      ? window.sessionStorage
      : window.localStorage;
    const newSession = {
      ...JSON.parse((inSessionStorage || inLocalStorage)!),
      [key]: value,
    };
    storage.setItem(sessionKey, JSON.stringify(newSession));
  }
}

export function removeSession() {
  window.sessionStorage.removeItem(sessionKey);
  window.localStorage.removeItem(sessionKey);
}
