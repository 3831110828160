import React, { memo, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { getUserHasToken } from 'modules/selectors';
import * as pathnames from '../pathnames';

function notLoggedIn<T extends RouteComponentProps>(
  WrappedComponent: ComponentType<T>
) {
  const NotLoggedInCheck = (props: T) => {
    if (!useSelector(getUserHasToken)) {
      return <WrappedComponent {...props} />;
    }

    return <Redirect from={props.location.pathname} to={pathnames.DASHBOARD} />;
  };
  return memo(NotLoggedInCheck);
}

export default notLoggedIn;
