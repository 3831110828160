import { sendLogout } from 'services/api';
import { removeSession } from 'services/storage';

import { getUserRequest } from '../selectors';
import {
  startLoading,
  finishLoading,
  logoutSuccess,
  logoutFailure,
} from '../actions';

type ThunkAction = import('store/types').ThunkAction;

export const logout = (): ThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    removeSession();
    await sendLogout(getUserRequest(getState()));
    dispatch(logoutSuccess());
  } catch (e) {
    dispatch(logoutFailure(e));
  }

  dispatch(finishLoading());
};
