import produce from 'immer';

import { ROSTER_GROUP_TYPE } from '../actionTypes';


export const initialState = {
  group: {}
};

const rosterGroupReducer = (
  state = initialState,
  action: import('store/types').Action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case ROSTER_GROUP_TYPE: {
        draft.group = action.payload.group;
      }
    }
  });


export default rosterGroupReducer;
