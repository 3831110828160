export const AUTH = '/auth';
export const AUTH_LOGIN = `${AUTH}/login`;
export const AUTH_REGISTER = `${AUTH}/register`;
export const AUTH_RESET_PASSWORD = `${AUTH}/reset-password`;
export const DASHBOARD = '/dashboard';
export const DASHBOARD_ACCOUNT = `${DASHBOARD}/account`;
export const ASSIGNMENTS = `${DASHBOARD}/assignments`;
export const ASSIGNMENTS_CURRENT = `${ASSIGNMENTS}/current`;
export const MANAGE_GROUPS = `${DASHBOARD}/manage-groups`;
export const MANAGE_GROUPS_ASSIGNMENTS = `${MANAGE_GROUPS}/assignments`;
export const MANAGE_GROUPS_ASSIGNMENTS_CREATE = `${MANAGE_GROUPS_ASSIGNMENTS}/create`;
export const MANAGE_GROUPS_ASSIGNMENTS_EDIT = `${MANAGE_GROUPS_ASSIGNMENTS}/:id`;
export const MANAGE_GROUPS_REPORTS = `${MANAGE_GROUPS}/reports`;
export const MANAGE_GROUPS_ROSTER = `${MANAGE_GROUPS}/roster`;
export const MANAGE_GROUPS_SUBSCRIPTIONS = `${MANAGE_GROUPS}/subscriptions`;
export const MANAGE_GROUPS_ROSTER_CLOSED_GROUP = `${MANAGE_GROUPS_ROSTER}/closed`;
export const MANAGE_GROUPS_ROSTER_VIEW_CLOSED_GROUP = `${MANAGE_GROUPS_ROSTER}/view`;
export const MANAGE_GROUPS_ROSTER_OPEN_GROUP = `${MANAGE_GROUPS_ROSTER}/open`;
export const READER = `/r`;
export const CUSTOMER_SUPPORT = '/customer-support';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';
