import {
  loadUserGroups,
  loadUserLicenses,
  loadUserLicensesInfo,
  loadUserWorkbooks,
  loadUserWorkbookImages,
} from 'services/api';
import {
  getCachedWorkbooks,
  getCachedImages,
  updateCachedWorkbooks,
  updateCachedImages,
} from 'services/storage';

import { getUserRequest, getUserId } from '../selectors';
import {
  startLoading,
  finishLoading,
  loadSubscriptionsSuccess,
  loadSubscriptionsInfoSuccess,
  loadSubscriptionsFailure,
} from '../actions';

type ThunkAction = import('store/types').ThunkAction;

export const loadSubscriptions = (payload: {
  noLoading?: boolean;
  getLicenseInfo?: boolean;
}): ThunkAction => async (
  dispatch,
  getState
) => {
  if(!payload.noLoading) dispatch(startLoading());

  try {
    const state = getState();
    const req = getUserRequest(state);
    const userId = getUserId(state);

    // 1. Load groups
    // 2. Create list of uuids of licenses which are related to these groups
    // 3. Load user's licenses and licenses from the list
    // 4. Create list of ids of workbooks used in loaded licenses
    // 5. Get workbooks and images which are already in cache
    // 6. Load all required workbooks and images using comparison of requested
    //    and cache-stored ids
    // 7. Update cache for workbooks and images

    const groups = await loadUserGroups(req, { userId });

    const closedGroupLicensesUuids = Object.values(groups).reduce(
      (list, group) => {
        if (group.open) {
          return list;
        }
        return [...list, group.licenseUuid];
      },
      [] as string[]
    );
    let licenses;
    if (payload.getLicenseInfo) {
      licenses = await loadUserLicensesInfo(req, {
        userId,
        closedGroupLicensesUuids,
      });
    } else {
      licenses = await loadUserLicenses(req, {
        userId,
        closedGroupLicensesUuids,
      });
    };

    const cachedWorkbooks = getCachedWorkbooks();
    const requiredWorkbooksIds = Object.values(licenses).reduce(
      (list, license) => {
        if (!list.includes(license.workbookId)) {
          return [...list, license.workbookId];
        }
        return list;
      },
      [] as number[]
    );
    const loadedWorkbooks = await loadUserWorkbooks(req, {
      requiredWorkbooksIds,
      cachedWorkbooksIds: Object.keys(cachedWorkbooks).map(Number),
    });
    const workbooks = {
      ...loadedWorkbooks,
      ...Object.values(cachedWorkbooks).reduce(
        (result, cached) => {
          if (requiredWorkbooksIds.includes(cached.id)) {
            return { ...result, [cached.id]: cached };
          }
          return result;
        },
        {} as import('models/workbooks').WorkbooksById
      ),
    };
    updateCachedWorkbooks({ ...cachedWorkbooks, ...loadedWorkbooks });

    const cachedImages = getCachedImages();
    const requiredImagesIds = Object.values(workbooks).reduce(
      (list, workbook) => {
        if (!list.includes(workbook.imageId)) {
          const newList = [...list, workbook.imageId];
          if (
            workbook.workbookImageId &&
            !list.includes(workbook.workbookImageId)
          ) {
            newList.push(workbook.workbookImageId);
          }
          return newList;
        }
        return list;
      },
      [] as number[]
    );
    const workbookImages = await loadUserWorkbookImages(req, {
      requiredImagesIds,
      cachedImagesIds: Object.keys(cachedImages).map(Number),
    });
    const allWorkbookImages = { ...cachedImages, ...workbookImages };
    updateCachedImages(allWorkbookImages);
      if (payload.getLicenseInfo) {
        dispatch(
          loadSubscriptionsInfoSuccess({
            groups,
            licenses,
            workbooks,
            workbookImages: allWorkbookImages,
          })
        );
      } else {
        dispatch(
          loadSubscriptionsSuccess({
            groups,
            licenses,
            workbooks,
            workbookImages: allWorkbookImages,
          })
        );
      }
  } catch (e) {
    console.error(e);
    dispatch(loadSubscriptionsFailure(e));
  }

  if(!payload.noLoading) dispatch(finishLoading());
};
