function getResponseValue(
  data: Record<string, any>,
  key: string,
  defaultValue?: any
) {
  const item = data[key];

  if (!Array.isArray(item) || item.length === 0) {
    return defaultValue || item;
  }

  if (item.length === 1) {
    if (typeof item[0] !== 'object') {
      return item;
    }

    const keys = Object.keys(item[0]);
    if (keys.length === 1 && keys[0] === 'value') {
      return item[0].value;
    }
  }

  return item;
}

export default getResponseValue;
