import FetchError from './FetchError';
import FetchResult from './FetchResult';

async function fetch<R = any, E = any>(request: Request) {
  const response = await window.fetch(request);

  const contentType = response.headers.get('Content-Type');
  const responseData = contentType
    ? /^application\/(?:(?:(?!\+json).+\+json$)|(?:json$))/.test(contentType)
      ? await response.json()
      : await response.text()
    : await response.blob();

  if (response.status >= 400 && response.status < 600) {
    throw new FetchError<E>(response.status, response.statusText, responseData);
  }
  return new FetchResult<R>(response.status, responseData);
}

export default fetch;
