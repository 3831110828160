import React from 'react';

import Icon from 'components/Icon';

import styles from './FluencyLinkDialog.module.scss';

type Props = {
  onClose: () => void;
};

const FluencyLinkDialog = ({ onClose }: Props) => {
  return (
    <>
      <div className={styles.header}>
        MIGRATION TO FLUENCYLINK
        <button className={styles.btn} onClick={onClose} aria-label="Close" autoFocus>
          <Icon name="close" />
        </button>
      </div>
      <div className={styles.content}>
        <p>
          <strong>Dear User,</strong><br />
          Thank you for using the Cheng & Tsui Web App. By now, you should have received your access code(s) to transfer your license and begin using FluencyLink at your convenience. We kindly urge you to explore the new platform, review our <a className={styles.link} href="https://www.cheng-tsui.com/fluencylink-help-page" target="_blank" rel="noopener noreferrer">
          Help & Support</a> documentation, and submit any <a className={styles.link} href="https://docs.google.com/forms/d/e/1FAIpQLSf9jl2q7OfmJDfpCWKln8sUmy3B2qZzSrEO7T0RyQm12GK6KQ/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">feedback</a> you would be willing to share. 
        </p>
        <p>
          Your access to the Web App will expire on or before June 30, 2023, so we recommend familiarizing yourself with FluencyLink and transferring any assignments you have created as soon as possible.
        </p>
        <p>
          Please reach out to <a className={styles.link} href="mailto:fluencylink-migration@cheng-tsui.com" target="_blank" rel="noopener noreferrer">fluencylink-migration@cheng-tsui.com</a> with any questions, and thank you again for working with Cheng & Tsui.
        </p>
      </div>
    </>
  );
};

export default FluencyLinkDialog;
