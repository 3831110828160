import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import {
  loading,
  user,
  subscriptions,
  audioModal,
  rosterGroups,
  rosterGroup,
} from 'modules/reducers';
import { Store } from './types';

const staticReducers = {
  loading,
  user,
  subscriptions,
  audioModal,
  rosterGroups,
  rosterGroup,
};

function createReducer(asyncReducers?: Store['asyncReducers']) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  }) as any;
}

function configureStore(initialState?: any) {
  const middleware = [applyMiddleware(thunk)];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(
      require('components/DevTools').default.instrument(undefined, {
        maxAge: process.env.REACT_APP_DEVTOOLS_MAX_AGE,
      })
    );
  }

  const store = createStore(
    createReducer(),
    initialState,
    compose(...middleware)
  ) as Store;
  store.asyncReducers = {};
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };
  return store;
}

export default configureStore;
