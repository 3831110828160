import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import store from './store/instance';

import App from './components/App';
import Root from './pages/Root';

ReactDOM.render(
  <ReduxProvider store={store as any}>
    <App>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </App>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
