import React, { memo, HTMLProps } from 'react';
import classnames from 'classnames/bind';

import { propsEqual } from 'utils/memo';

import styles from './Icon.module.scss';
const cx = classnames.bind(styles);

type Props = {
  name: 'close' | 'menuUp' | 'menuDown' | 'chevronDown';
  className?: string;
} & HTMLProps<'i'>;

const Icon = ({ name, className }: Props) => (
  <i className={cx('icon', `--${name}`, className)} />
);

export default memo(Icon, propsEqual(['name', 'className']));
