import React, { memo, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { getUserHasToken } from 'modules/selectors';
import * as pathnames from '../pathnames';

function loggedIn<T extends RouteComponentProps>(
  WrappedComponent: ComponentType<T>
) {
  const LoggedInCheck = (props: T) => {
    if (useSelector(getUserHasToken)) {
      return <WrappedComponent {...props} />;
    }

    return (
      <Redirect from={props.location.pathname} to={pathnames.AUTH_LOGIN} />
    );
  };
  return memo(LoggedInCheck);
}

export default loggedIn;
