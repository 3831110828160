import React, { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';

import * as pathnames from 'pathnames';

import { submitAgreePrivacyPolicy } from 'modules/allActions';
import Button from 'components/Button';

import styles from './PrivacyPolicyDialog.module.scss';
const cx = classnames.bind(styles);

type Props = {
  onDisagree: Function;
}

const PrivacyPolicyDialog = ({ onDisagree }: Props) => {
  const dispatch = useDispatch();
  const focusRef = useRef<HTMLParagraphElement>(null);

  useLayoutEffect(() => {
   setTimeout(() => focusRef && focusRef.current && focusRef.current.focus(), 400);
  }, [focusRef])

  const disagree = () => {
    onDisagree();
  }

  const agree = () => {
    dispatch(submitAgreePrivacyPolicy());
  }

  return (
    <div className={cx('privacyPolicyDialog')}>
      <div className={cx('privacyPolicyDialog_header')} />
      <div className={cx('privacyPolicyDialog_body')}>
        <div className={cx('privacyPolicyDialog_confirm')}>
          <p tabIndex={0} ref={focusRef}>Please, confirm that you agree with our </p>
          <a href={pathnames.TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
          <span>and</span>
          <a href={pathnames.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
        <div className={cx('privacyPolicyDialog_buttons')}>
          <Button
            className={cx('privacyPolicyDialog_agree')}
            onClick={agree}
            color={2}
          >
            I agree
          </Button>
          <Button
            className={cx('privacyPolicyDialog_disagree')}
            onClick={disagree}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyDialog;
