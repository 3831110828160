import { fetchGet, route, query } from 'lib/fetchFns';

type GroupsLoadResponse = import('models/groups').GroupsLoadResponse;
type GroupsById = import('models/groups').GroupsById;
type LoadUserGroupsPayload = {
  userId: number;
};

export async function loadUserGroups(
  req: Request,
  payload: LoadUserGroupsPayload
) {
  const request = query(route(req, '/og-membership'), {
    _format: 'json',
    ___t: new Date().getTime().toString(),
  });
  const { data } = await fetchGet<GroupsLoadResponse>(request);
  return data.reduce(
    (result, group) => {
      result[group.id] = {
        id: group.id,
        uuid: group.uuid,
        label: group.label,
        createdDate: group.createdDate,
        startDate: group.startDate,
        open: group.type === 'open',
        licenseUuid: group.licenseUuid,
      };
      return result;
    },
    {} as GroupsById
  );
}
