import { sendAgreePrivacyPolicy } from 'services/api';

import { getUserRequest } from '../selectors';

import {
  startLoading,
  finishLoading
} from '../actions';

import { checkUserToken } from './checkUserToken';

type ThunkAction = import('store/types').ThunkAction;

export const submitAgreePrivacyPolicy = (): ThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const state = getState();
    await sendAgreePrivacyPolicy(getUserRequest(state), {
      userId: state.user.uuid
    });
    dispatch(checkUserToken());
  } catch (e) {
    console.error(e);
  }

  dispatch(finishLoading());
};
