import produce from 'immer';

import {
  SUBSCRIPTIONS_LOAD,
  SUBSCRIPTIONS_LOAD_INFO,
  SUBSCRIPTIONS_ACTIVATE_LICENSE,
  USER_LOGOUT,
} from '../actionTypes';

export const initialState = {
  groups: {} as import('models/groups').GroupsById,
  licenses: {} as import('models/licenses').LicensesById,
  licensesInfo: {} as import('models/licenses').LicensesById,
  workbooksInfo: {} as import('models/workbooks').WorkbooksById,
  workbooks: {} as import('models/workbooks').WorkbooksById,
  workbookImages: {} as import('models/workbookImages').WorkbookImagesById,
  isLoaded: false,
};

const subscriptionsReducer = (
  state = initialState,
  action: import('store/types').Action
) =>
  produce(state, draft => {
    switch (action.type) {
      case SUBSCRIPTIONS_LOAD: {
        if (!action.error) {
          draft.groups = action.payload.groups;
          draft.licenses = action.payload.licenses;
          draft.workbooks = action.payload.workbooks;
          draft.workbookImages = action.payload.workbookImages;
        }
        draft.isLoaded = true;
        break;
      }
      case SUBSCRIPTIONS_LOAD_INFO: {
        if (!action.error) {
          draft.licensesInfo = action.payload.licenses;
          draft.workbooksInfo = action.payload.workbooks;
        }
        draft.isLoaded = true;
        break;
      }
      case SUBSCRIPTIONS_ACTIVATE_LICENSE: {
        if (!action.error) {
          draft.licenses[action.payload.licenseId].isActivated = true;
          draft.licenses[action.payload.licenseId].expiryDate =
            action.payload.expiryDate;
        }
        break;
      }
      case USER_LOGOUT: {
        return initialState;
      }
    }
  });

export default subscriptionsReducer;
