import React from 'react';
import { isBefore } from 'date-fns';
import classnames from 'classnames/bind';

import styles from './MaintenanceMessage.module.scss';
const cx = classnames.bind(styles);

const MaintenanceMessage = () => {
  if (isBefore(new Date(), new Date('Tue, 12 Jul 2021 20:00:00 EST'))) {
    return (
      <div className={cx('message')}>
        We have been experiencing issues with data failing to save on the Web
        App, including group membership, assignments, and resetting passwords.
        This has also caused a delay in adding subscriptions purchased after
        July 2nd. We will be working on these issues throughout the evening and
        hope to have them resolved by <strong>8am EST on July 12th</strong>. We
        appreciate your patience while we focus on these corrections.
      </div>
    );
  }
  return null;
};

export default MaintenanceMessage;
