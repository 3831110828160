import React, { memo, Suspense, useEffect, ComponentType, ReactNode, ReactNodeArray } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getIsUserTokenChecked,
  getUserIsLoggedIn,
  getUserId,
  getIsLoading,
  getIsSubscriptionsLoaded,
} from 'modules/selectors';
import { checkUserToken, loadSubscriptions } from 'modules/allActions';

import Loader from '../Loader';
import Loading from '../Loading';

let DevTools: ComponentType | null = null;
let DevToolsWrapper: ComponentType | null = null;
if (process.env.NODE_ENV === 'development') {
  DevTools = require('../DevTools').default;
  DevToolsWrapper = require('../DevToolsWrapper').default;
}

type Props = {
  children: ReactNode | ReactNodeArray;
};

const App = ({ children }: Props) => {
  const dispatch = useDispatch();
  // Checking persistent token on first render
  useEffect(() => {
    dispatch(checkUserToken());
  }, [dispatch]);

  const userIsLoggedIn = useSelector(getUserIsLoggedIn);
  const userId = useSelector(getUserId);
  // Load subscriptions when token was checked and user info was set
  useEffect(() => {
    if (userIsLoggedIn) {
      dispatch(loadSubscriptions({}));
    }
  }, [dispatch, userIsLoggedIn, userId]);

  const isTokenChecked = useSelector(getIsUserTokenChecked);
  const isSubscriptionsLoaded = useSelector(getIsSubscriptionsLoaded);
  return (
    <>
      {DevTools && DevToolsWrapper && (
        <DevToolsWrapper>
          <DevTools />
        </DevToolsWrapper>
      )}
      <Loader active={useSelector(getIsLoading)} />
      {isTokenChecked && (!userIsLoggedIn || isSubscriptionsLoaded) && (
        <Suspense fallback={<Loading />}>{children}</Suspense>
      )}
    </>
  );
};

export default memo(App);
