import produce from 'immer';

import { CHANGE_MODAL_AUDIO_DATA } from '../actionTypes';


export const initialState = {
  src: '',
  title: '',
};

const audioModalReducer = (
  state = initialState,
  action: import('store/types').Action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_MODAL_AUDIO_DATA: {
        draft.src = action.payload.src;
        draft.title = action.payload.title;
      }
    }
  });

export default audioModalReducer;
