import { loadUserToken, loadUserInfo } from 'services/api';
import { removeSession } from 'services/storage';

import { getUserRequest } from '../selectors';
import {
  startLoading,
  finishLoading,
  checkUserTokenSuccess,
  checkUserTokenFailure,
} from '../actions';

type ThunkAction = import('store/types').ThunkAction;

export const checkUserToken = (): ThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const state = getState();
    const req = getUserRequest(state);

    const token = await loadUserToken(req);
    const info = await loadUserInfo(req);
    dispatch(
      checkUserTokenSuccess({
        token,
        id: info.id,
        uuid: info.uuid,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        username: info.username,
        institution: info.institution,
        occupation: info.occupation,
        country: info.country,
        state: info.state,
        agree_privacy_policy: info.agree_privacy_policy,
        emailUpdatesEnabled: info.emailUpdatesEnabled,
        highlights: info.highlights,
        isBetaTester: info.isBetaTester,
      })
    );
  } catch (e) {
    removeSession();
    dispatch(checkUserTokenFailure(e));
  }

  dispatch(finishLoading());
};
